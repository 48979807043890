import { defineAsyncComponent, hydrateOnVisible } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
// import lazyComponent from 'public/src/services/lazyComponent/lazy-hydrate' // 组件懒加载
import ImmersiveBanner from '../home-bff-components/immersive-banner/Index.vue'
import ImmersiveBannerSmooth from '../home-bff-components/immersive-banner/IndexNew.vue'
import FreeShippingInfo from '../home-bff-components/free-shipping/index.vue'

/** 首页组件
 * TODO: 增加组件后需在comp-bff-map 文件中检查是否存在对应的组件映射
 */
const componentsMap = {
  // 店铺横向卡片列表
  'StaticImage': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-static-image" */'../home-bff-components/static-image/StaticImageFactory'),
    hydrate: hydrateOnVisible(),
  }),
  'StaticImageCardController': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-static-image" */'../home-bff-components/static-image-card-controller/index.vue'),
    hydrate: hydrateOnVisible(),
  }),

  'SideslipImage': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-sideslip-image" */'../home-bff-components/sideslip-image-static/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 灵活布局
  'MultiLine': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-multi-line" */'../home-bff-components/multi-line/Index.vue'),
    hydrate: hydrateOnVisible(),
  }),

  // 沉浸式banner
  // 'ImmersiveBanner': () => import(/* webpackChunkName: "bff-immersive-banner" */'../home-bff-components/immersive-banner/Index.vue'),
  'ImmersiveBanner': ImmersiveBanner,
  'ImmersiveBannerSmooth': ImmersiveBannerSmooth,

  // 免邮
  'FreeShippingInfo': FreeShippingInfo,
  // defineAsyncComponent({
  //   loader: () => import(/* webpackChunkName: "bff-free-shipping" */'../home-bff-components/free-shipping/index.vue'),
  //   hydrate: hydrateOnVisible(),
  // }),
  
  // 三档券
  'CouponPackage': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-coupon-package" */'../home-bff-components/coupon-package/Index.vue'),
    hydrate: hydrateOnVisible(),
  }),

  // 品类推荐（非占位） 非All 频道使用
  'CategoryRecommendDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-category-recommend-dynamic" */'../home-bff-components/category-recommend-dynamic/index.vue'),
    hydrate: hydrateOnVisible(),
  }),

  // 排行榜入口
  'RankingEntrance': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ranking-entrance" */'../home-bff-components/ranking-entrance/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 新客组件
  'NewUserHome': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-new-user-home" */'../home-bff-components/new-user-home/Index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // TODO： 后续组件迁移目录到bff 
  // SBC 品类热度推荐 占位&产品化 组件 （All 频道使用）
  'CategoryRecommendPlaceholder': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-category-recommend-placeholder" */'../home-bff-components/category-recommend-placeholder/sbc.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 首页凑单券
  'PurchaseCouponBanner': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-purchase-coupon-banner" */'../home-bff-components/purchase-coupon-banner/index.vue'),
    hydrate: hydrateOnVisible(),
  }),

  // 新客权益融合组件
  'PolicyNewUserContainer': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "new-user-profits" */'../home-bff-components/new-user-profits/MainContainer.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 购物车楼层组件
  'CartInterresetPics': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cart-interreset-pics" */'../home-bff-components/cart-interreset-pics/CartFloorIndex.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 游戏专区
  'GameZone': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "game-zone" */'../home-bff-components/game-zone/GameZone.vue'),
    hydrate: hydrateOnVisible(),
  }),
}

// const components = Object.entries(componentsMap).reduce((prev, cur) => {
//   if (['HorizontalAnchor', 'HorizontalAnchorImage', 'Welfare', 'ImmersiveBanner'].includes(cur[0])) {
//     prev[`${cur[0]}`] = cur[1]
//   } else {
//     prev[`${cur[0]}`] = lazyComponent({
//       // propWhole: true,
//       componentFactory: cur[1],
//       customProps: {
//         index: 'number',
//         propData: 'object',
//         propHooks: 'object',
//         content: 'array',
//         context: 'object',
//         sceneData: 'object',
//         cateLinks: 'object',
//         language: 'object',
//         brand: 'string',
//         isLast: 'boolean',
//         isLoad: 'boolean',
//         lazyForceShow: 'boolean'
//       }
//     })
//   }
//   return prev
// }, {})

componentsMap['ClientOnly'] = ClientOnly

// 高频使用的组件
const PREFETCH_COMPONENTS = {
  'StaticImage': 'bff-static-image',
  'StaticImageCardController': 'bff-static-image',
  'SideslipImage': 'bff-sideslip-image',
  'CategoryRecommendDynamic': 'bff-category-recommend-dynamic',
  'CategoryRecommendPlaceholder': 'bff-category-recommend-placeholder',
  'MultiLine': 'bff-multi-line',
  'CouponPackage': 'bff-coupon-package',
  'PurchaseCouponBanner': 'ccc-purchase-coupon-banner',
  'FreeShippingInfo': 'bff-free-shipping'
}
export const generateHighFrequencyComponents = () => {
  return Object.keys(PREFETCH_COMPONENTS).map(key => ({
    chunkName: PREFETCH_COMPONENTS[key],
    resolve: componentsMap[key]
  })).filter(n => !!n.chunkName)
}

export default componentsMap
